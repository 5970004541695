/**
 * @flow
 */
import { fetchWithNoExpectedResponseData } from "../../shared/fetchWithAuth";

document.addEventListener("DOMContentLoaded", () => {
  const deleteButton = document.getElementById("logout-banner-delete");
  const banner = document.getElementById("logout-banner");
  if (deleteButton && banner) {
    deleteButton.onclick = () => {
      banner.style.display = "none";
      // If the API call fails, it's not that bad, and there's no recovery path, so we don't handle the promise.
      // noinspection JSIgnoredPromiseFromCall
      fetchWithNoExpectedResponseData(`${window.location.origin}/api/v1/logout-banner/close`, { method: "POST" });
    };
  }
});
